/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-no-parking-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.292 14A8 8 0 012 2.707l3.5 3.5V12h1.283V9.164h1.674zm.708-.708l-4.37-4.37C10.5 8.524 11 7.662 11 6.587c0-1.482-.955-2.584-2.538-2.584H5.5v.79L2.708 2.002A8 8 0 0114 13.293z"/><path pid="1" d="M6.777 7.485v.59h.59zm1.949.535L6.777 6.07v-.966H8.27c.893 0 1.419.539 1.419 1.482 0 .769-.35 1.273-.963 1.433z"/>',
    },
});
